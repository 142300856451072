import React,{useState, useEffect} from 'react';
import './reset.css';
import './main.scss';
import Layout from '../components/Layout';
import {CopyIcon, Arrow} from '../components/svgIcons';
import { Script, useStaticQuery, graphql } from 'gatsby';
import {getColorPreference, setPreference} from '../scripts/choose-theme';
import { StaticImage } from 'gatsby-plugin-image';

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }`;

export const Head = ({data}) =>{
  return (<>
    <title>{data.site.siteMetadata.title}</title>
    <meta name='color-scheme' content='dark light' />
    <meta name='description' content={data.site.siteMetadata.title} />
    <meta name='google' content='nositelinkssearchbox' />
    <meta name="googlebot" content='notranslate' />
  </>);
};

const IndexPage = () => {
  //const revealButtonRef = useRef(null);
  const [email,setEmail] = useState();
  const [buttonDisabled,setButtonDisabled] = useState(false);
  const [explode,setExplode] = useState(false);
  const [emailVisible,setEmailVisible] = useState(false);
  const [hideButton,setHideButton] = useState(false);
  const [copied,setCopied] = useState(false);
  const [theme,setTheme] = useState(getColorPreference());
  let revealTimeout;
  let explodeTimeout;

  const encRot13 = (mailString) => {
    var mapArray = [];
    var elements = 'abcdefghijklmnopqrstuvwxyz';
    var outp = '';
    for (let i = 0; i < elements.length; i++) {
      var x = elements.charAt(i);
      var y = elements.charAt((i + 13) % 26);
      mapArray[x] = y;
      mapArray[x.toUpperCase()] = y.toUpperCase();
    }
    for (let i = 0; i < mailString.length; i++) {
      var c = mailString.charAt(i);
      outp += (c >= 'A' && c <= 'z' || c >= 'a' && c <= '\\' ? mapArray [c] : c);
    }
    return outp;
  };

  // const decryptMail = (encString) => {

  //   return encRot13(encString);

  //   //document.location.href = linkString;
  // };

  const getEncEmail = () => {
    const part1 = 'uryyb';
    const part2 = 'unaf';
    const part3 = '.jbexf';
    return `${part1}@${part2}${part3}`;
  };

  const revealEmail = (e) => {
    e.stopPropagation();
    setButtonDisabled(true);
    setExplode(true);
    if(!revealTimeout){
      revealTimeout = setTimeout(()=>{
        if(emailVisible){
          setHideButton(true);
        }
      },500);
    }
    setEmail(encRot13(getEncEmail()));
    setEmailVisible(true);
  };

  const clickCopy = (e,email) => {
    e.stopPropagation();
    try{
      navigator.clipboard.writeText(email);
      setCopied(true);
    }
    catch(err){
      console.error(err);
    }
    setTimeout(()=>{
      setCopied(false);
    },1000);
  };

  // useEffect(()=>{
  //   if(window.matchMedia('(prefers-color-scheme: dark)').matches){
  //     setTheme('dark');
  //   }
  //   else{
  //     setTheme('light');
  //   }
  // },[]);

  useEffect(()=>{
    if(explode && !explodeTimeout){
      explodeTimeout = setTimeout(()=>{
        setExplode(false);
        setHideButton(true);
      },500);

    }
  },[explode]);


  useEffect(()=>{
    setPreference(theme);
  },[theme]);

  const reverseExplosion = (e) => {
    var contactEl = document.getElementById('contact');
    e.stopPropagation();
    if(emailVisible && event.target.id !== 'contact' && !contactEl.contains(e.target)){
      clearTimeout(revealTimeout);
      clearTimeout(explodeTimeout);
      setExplode(false);
      setEmailVisible(false);
      setHideButton(false);
      setButtonDisabled(false);
    }

  };

  const toggleTheme = (e) => {
    e.stopPropagation();
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
  };

  return (
    <Layout onClick={reverseExplosion} >
      <div className='top-spacer'></div>
      <div className='content-wrapper'>
        <div className='title'>I’m Hans,<br />a freelance <span className='no-break'>web developer.</span></div>
        <div className='description'>*1984 in Germany<br />I also work with photography and started exploring photo driven designs on the web. Right now I am working on a web app called <a href='https://emsig.app' target='_blank' rel='noreferrer'><i className='no-break'>emsig.app</i></a>, which I really love. Being able to choose contemporary technologies is very exciting and I am very interested in diving deeper into progressive web apps.</div>
      </div>
      <div className='footer'>
        <div  className={`splatter ${emailVisible ? '' :'invisible'}`}>
          <StaticImage
            src='../images/splatter3_l@2x.png'
            layout="fixed"
            width={716}
            height={629}
            loading='eager'
            alt='color splashes'
            aria-hidden='true' />
        </div>
        {/*<div className={`splatter ${emailVisible ? '' :'invisible'}`}></div>*/}
        <div id='contact' className='contact'>
          <div className='address' style={{...(emailVisible && {height:'100%',opacity:1})}}>
            {email && <div className='email-text-wrapper'><div>{email}</div><div className={`copy ${copied ? 'animate-copy' : 'hide'}`}>{email}</div></div>}
            <button className={'copy-button'} onClick={(e)=>clickCopy(e,email)} style={{...(!emailVisible && {display:'none'})}}><CopyIcon className={'copy-icon'} />copy</button>
            {/*<div className={'tip'} style={{...(!emailVisible && {display:'none'})}}><Arrow className='tip-arrow' /><span>copy to clipboard</span></div>*/}
          </div>
          <button disabled={buttonDisabled} className={`contact-button ${explode ? 'explode' :''} ${hideButton ? 'hide' : ''}`} onClick={revealEmail} >
            show contact
            <div className='border'></div>
            <div className='border'></div>
            <div className='border'></div>

          </button>
          {/*<button className='reveal-button' onClick={revealEmail} style={{...(emailVisible && {visibility:'hidden'})}}>show contact</button>*/}
        </div>
        <div className='skills-title'>work will follow soon</div>
        <div className='skills'>html, css, javascript, git, node, react, sails, php, sql<br />photography, climbing, cooking</div>

      </div>
      <button onClick={toggleTheme} className='theme-toggle' id='theme-toggle' title='Toggles light & dark' aria-label='auto' aria-live='polite'>
        <svg className="sun-and-moon" aria-hidden="true" width="24" height="24" viewBox="0 0 24 24">
          <circle className="sun" cx="12" cy="12" r="6" mask="url(#moon-mask)" />
          <g className="sun-beams" >
            <line x1="12" y1="1" x2="12" y2="3" />
            <line x1="12" y1="21" x2="12" y2="23" />
            <line x1="4.22" y1="4.22" x2="5.64" y2="5.64" />
            <line x1="18.36" y1="18.36" x2="19.78" y2="19.78" />
            <line x1="1" y1="12" x2="3" y2="12" />
            <line x1="21" y1="12" x2="23" y2="12" />
            <line x1="4.22" y1="19.78" x2="5.64" y2="18.36" />
            <line x1="18.36" y1="5.64" x2="19.78" y2="4.22" />
          </g>
          <mask className="moon" id="moon-mask">
            <rect x="0" y="0" width="100%" height="100%" fill="white" />
            <circle cx="24" cy="10" r="6" fill="black" />
          </mask>
        </svg>
      </button>
    </Layout>
  );
};

export default IndexPage;
