const storageKey = 'theme-preference';
const isBrowser = () => typeof window !== 'undefined';
export const getColorPreference = () => {
  if(isBrowser()){
    if (localStorage.getItem(storageKey)){
      return localStorage.getItem(storageKey);
    }
    else{
      return window.matchMedia('(prefers-color-scheme: dark)').matches
        ? 'dark'
        : 'light';
    }
  }else{
    return 'light';
  }

};

const addThemeClass = (el,theme) => {
  var regx = new RegExp('\\b' + 'theme--' + '[^ ]*[ ]?\\b', 'g');
  el.className = el.className.replace(regx, '');
  el.classList.add(`theme--${theme}`);
};

const reflectPreference = (theme) => {
  // document.firstElementChild
  //   .setAttribute('data-theme', theme);
  const el = document.body;
  if(el){
    addThemeClass(el,theme);
  }


  // const toggle = document
  //   .querySelector('#theme-toggle');
  // if(toggle){
  //   toggle.setAttribute('aria-label', theme);
  // }
};

export const setPreference = (theme) => {
  if(isBrowser()){
    localStorage.setItem(storageKey, theme);
  }
  reflectPreference(theme);
};


