import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const Layout = ({ children, ...props }) => {
  return (
    <main {...props}>
      {children}
    </main>
  );
};
Layout.propTypes = {
  children:PropTypes.node,
  theme: PropTypes.string
};
export default Layout;
