import React from 'react';
import PropTypes from 'prop-types';
export const CopyIcon = ({className, ...props}) => {
  return (
    <svg className={className} {...props} xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 16 16`}><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path id="regular_clone" data-name="regular clone" d="M14.5,0H4.5A1.5,1.5,0,0,0,3,1.5V3H1.5A1.5,1.5,0,0,0,0,4.5v10A1.5,1.5,0,0,0,1.5,16h10A1.5,1.5,0,0,0,13,14.5h0V13h1.5A1.5,1.5,0,0,0,16,11.5V1.5A1.5,1.5,0,0,0,14.5,0ZM11.31,14.5H1.69a.18.18,0,0,1-.19-.19V4.69a.18.18,0,0,1,.19-.19H3v7A1.5,1.5,0,0,0,4.5,13h7v1.31A.18.18,0,0,1,11.31,14.5Zm3-3H4.69a.18.18,0,0,1-.19-.19h0V1.69a.18.18,0,0,1,.19-.19h9.62a.18.18,0,0,1,.19.19v9.62A.18.18,0,0,1,14.31,11.5Z"/></g></g></svg>
  );
};
CopyIcon.propTypes = {
  className:PropTypes.string,
};

export const Arrow = ({className, ...props}) => {
  return (
    <svg className={className} {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 91.28 63.62"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path d="M91.28.5s-77,0-81,56" fill="none" strokeMiterlimit="10"/><path d="M30.28,40.5s-14,0-20,21c0,0-4-20-10-24" fill="none" strokeMiterlimit="10"/></g></g></svg>
  );
};
Arrow.propTypes = {
  className:PropTypes.string,
};
